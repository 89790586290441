<template>
    <div class="top-nav">
       
       <img src="../assets/png/logo.png" class="logo-activity" style="" alt="">
       
    </div>
    <div data-v-47f4cc84="" class="login__container-heading1">
       <h1 data-v-47f4cc84="" class="login__container-heading__title">Activity</h1>
       <div data-v-47f4cc84="" class="login__container-heading__subTitle">
          <div data-v-47f4cc84="">Please remember to follow the event page</div>
          <div data-v-47f4cc84="">We will launch user feedback activites from time to time</div>
       </div>
    </div>
    
 </template>
 <script>
    export default {
        name: "AcivityView",
      
    }
    
 </script>